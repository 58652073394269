import React from 'react';
import BasePortableText from '@sanity/block-content-to-react';

import { styled } from '../../../stitches.config';
import serializers from './Serializers';

const PortableText = ({ blocks, bgColor }) => (
  <Wrapper
    css={{
      '.highlight': {
        backgroundColor: `$${bgColor}`,
        padding: '3px',
      },
      '.apos': { color: `$${bgColor}` },
    }}
    blocks={blocks}
    serializers={serializers}
  />
);

export default PortableText;

const Wrapper = styled(BasePortableText);
