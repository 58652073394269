/* eslint-disable react/display-name */
import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { arta } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { styled } from '../../../stitches.config';

export default ({ node }) => {
  if (!node || !node.code) {
    return null;
  }
  const { blur, code } = node;

  const [blurred, setblurred] = useState(blur);

  return (
    <SyntaxHighlighterWrapper
      blur={blurred}
      css={{
        pre: {
          borderRadius: '$md !important',
          padding: '$4 !important',
          backgroundColor: '$dark2 !important',
          marginTop: '-7px !important',
          code: { transition: 'filter 0.3s', filter: blurred && 'blur(5px)' },
        },
      }}
    >
      {code.language && <span className="lang">.{code.language}</span>}

      {blurred && (
        <button
          type="button"
          onClick={() => setblurred(!blurred)}
          className="show"
        >
          answer
        </button>
      )}

      <SyntaxHighlighter style={arta} language={code.language || 'text'}>
        {code.code}
      </SyntaxHighlighter>
    </SyntaxHighlighterWrapper>
  );
};

const SyntaxHighlighterWrapper = styled('div', {
  paddingBottom: '$4',
  paddingTop: '$4',
  position: 'relative',
  '.show': {
    display: 'block',
    position: 'absolute',
    backgroundColor: 'inherit',
    top: '70px',
    right: '15px',
    zIndex: 1,
    color: '$secondary',
    fontSize: '$3',
    border: 'solid 2px $secondary',
    padding: '0.3rem 1.6rem',
    borderRadius: '$md',
    '&:hover': { cursor: 'pointer' },
  },
  '.lang': {
    borderRadius: '$md',
    padding: '$2 $3',
    backgroundColor: '$dark2',
    fontSize: '$3',
    color: '$rose',
  },
});
