import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { styled } from '../../../stitches.config';
import clientConfig from '../../../client-config';

const Figure = ({ node }) => {
  const imageData = node.blockImage.asset;
  if (!imageData) {
    return null;
  }

  const gatsbyImageData = getGatsbyImageData(
    node.blockImage.asset,
    { width: 600 },
    clientConfig.sanity,
  );

  return (
    <Wrapper>
      <GatsbyImage
        style={{ margin: '0 auto' }}
        image={gatsbyImageData}
        alt={node.alt}
      />
    </Wrapper>
  );
};

export default Figure;

const Wrapper = styled('div', {
  display: 'flex',
  padding: '$5',
});
