import * as React from 'react';
import { graphql } from 'gatsby';
import { styled } from '../../stitches.config';
import '@reach/tabs/styles.css';
import Layout from '../components/Layout';
import { Section } from '../components/Section';
import { Wrapper } from '../components/Wrapper';
import { Typography } from '../components/Typography';
import PortableText from '../components/Sanity/PortableText';
import Seo from '../components/Seo';

const Blog = ({ data: sanityData, data }) => {
  const { bgColor, title, _rawBody } = sanityData.sanityPost;

  return (
    <Layout
      pageTitle="Super Cool Blog Posts"
      nolinks
    >

      <Seo seoData={data.sanityPost} />

      <Wrapper>
        <Section
          css={{
            color: '$text',
            maxWidth: '$md',
            py: '$3',
          }}
        >
          <StyledTitle
            as="h2"
            css={{
              borderBottom: `6px solid $${bgColor}`,
              paddingBottom: '$3',
            }}
          >
            {title}
          </StyledTitle>
          <PortableTextWrapper css={{
            h3: {
              borderBottom: `6px solid $${bgColor}`,
              paddingBottom: '$4',
            },
            h4: {
              paddingBottom: '$4',
              p: {
                fontSize: '$3',
                marginBottom: '$4',
                code: {
                  bg: `$${bgColor}`,
                  fontFamily: 'Helvetica',
                  fontSize: '$3',
                  letterSpacing: '1px',
                  padding: '0.45rem 0.6rem',
                  margin: '1px -1px',
                  borderRadius: '$2',
                },
              },
            },
          }}
          >
            <PortableText bgColor={bgColor} blocks={_rawBody} />
          </PortableTextWrapper>
        </Section>
      </Wrapper>
    </Layout>

  );
};

export default Blog;

const PortableTextWrapper = styled('div');

const StyledTitle = styled(Typography);

export const query = graphql`
  query BlogPostTemplateQuery($id: String) {
    sanityPost(id: { eq: $id }) {
      id
      _rawBody
      title
      slug {
        current
      }
      description
      bgColor
      _rawCode
      body {
        _key
        _type
        style
        list
        _rawChildren
      }
      bgColor
      mainImage {
        asset {
          gatsbyImageData
        }
      }
      _createdAt(formatString: "MM DD YY")
    }
  }
`;
