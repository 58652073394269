/* eslint-disable react/display-name */
import React from 'react';
import Playground from '@agney/playground';
import * as Styled from './Serializers.styles';
import Figure from './Figure';
import Code from './Code';
import '@reach/tabs/styles.css';
import { Typography } from '../Typography';

const serializers = {
  marks: {
    highlight: ({ children }) => (
      <Typography className="highlight" as="span">
        {children}
      </Typography>
    ),
    small: ({ children }) => (
      <Typography fontSize="1.5rem" as="span">
        {children}
      </Typography>
    ),
    apos: ({ children }) => (
      <Typography className="apos" as="span">
        {children}
      </Typography>
    ),
  },

  types: {

    authorReference: ({ node }) => <span>{node.author.name}</span>,
    image: ({ node }) => <Figure node={node} />,
    code: ({ node }) => <Code node={node} />,
    playground: ({ node }) => (
      <Styled.PlaygroundContainer>
        <Playground
          id={node.key}
          transformJs
          presets={['react']}
          defaultEditorTab={node.defaultEditorTab}
          initialSnippet={{
            markup: node.html.code,
            css: node.css.code,
            javascript: node.js.code,
          }}
        />
      </Styled.PlaygroundContainer>
    ),
  },
};

export default serializers;
