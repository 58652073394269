import { styled } from '../../../stitches.config';

export const PlaygroundContainer = styled('div', {
  fontSize: '14px',
  fontFamily: 'sans-serif',
  paddingBottom: '$5',
  '.playground > div': {
    border: 'none',
    borderRadius: '$md',
    backgroundColor: '$dark2',
    minHeight: '40rem',
    '.bplg': { display: 'block' },
  },
  button: { color: '$light5', paddingBottom: '1rem' },
  'button[data-selected]': {
    color: '$primary',
    borderBottomColor: '$primary',
  },
  'div[data-reach-tabs]': {
    border: 'none',
    width: '100% !important',
    minHeight: '20rem',
  },
  'div[data-reach-tab-panels]': { height: 'auto' },
  'div[data-reach-tab-panel] > div': {
    backgroundColor: '$dark3 !important',
    "li[role='treeitem']": { backgroundColor: 'inherit !important' },
  },
});
